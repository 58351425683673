* {
    font-family: "Poppins", sans-serif;
    /*font-size: 16px;*/
}

.text-light-green {
    color: #1C5838;
}

.bg-dark-green {
    background-color: #1C5838;
}

.navbar-brand {
    font-size: 2em;
    font-weight: 700;
    color: #7AC943;
    text-transform: capitalize;
}

.navbar-brand:hover {
    color: white;
}

.navbar-brand img {
    height: 40px;
    width: auto;
}

.navbar-nav .nav-link {
    color: #FFFFFF;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
    color: #ffffff;
    border-bottom: solid 0px #1C5838;
}

.banner {
    position: relative;
    width: 100%;
    top: 0;
    /*z-index: -1;*/
    min-height: 100vh;
    background-image: url("/public/images/Graphic 1.png");
    background-color: #0DC31F;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 50%;
}

.main-text {
    font-size: 2.5em;
    font-weight: 400;
    line-height: 1.1em;
    color: #ffffff;
}

.about-wrapper {
    min-height: 100vh;
}

.about-wrapper img {
    width: 90%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
}

.bg-light-gray {
    background: #f1f1f1;
}

.feature-title {
    height: 70px;
    display: flex;
    align-items: center;
}

.feature-title h4 {
    font-weight: 700 !important;
    white-space: pre-line;
    line-height: 25px;
}

.feature-icon {
    font-size: 3.4em;
}

.feature-card {
    border-radius: 0 3em;
    height: 420px;
}

.feature-body {
    word-break: break-word;
    white-space: pre-line;
    font-weight: 400;
}

.niche-wrapper {
    margin-top: 7em;
}

.niche-wrapper img {
    height: 100%;
    width: auto;
    z-index: -1;
    border-radius: 16px;
}

.niche-wrapper .image-wrapper {
    height: 400px;
}

.niche-wrapper .icon-wrapper {
    background-color: #0DC31F;
    color: #FFFFFF;
    z-index: 1;
    top: -45px;
    /*right: -40px;*/
    padding: 0.5em 1em;
    border-radius: 15px 15px 0 15px;
}

.niche-wrapper .icon-wrapper-right {
    right: -40px;
}

.niche-wrapper .icon-wrapper-left {
    left: -40px;
}

.niche-wrapper .icon {
    font-size: 3em;
}

.niche-body {
    width: 100%;
    word-break: break-word;
    white-space: pre-line;
}

.niche-body .title {
    font-size: 2em;
    font-weight: 200;
}

.niche-body-right {
    left: -9em;
    border-left: solid 10px #0DC31F;
}

.niche-body-left {
    /*margin-left: 9em;*/
    /*position: absolute;*/
    border-right: solid 10px #0DC31F;
    right: -9em;
}

.get-in-touch {
    background-color: #0DC31F;
}

.get-in-touch iframe {
    width: 100%;
    height: 100vh;
}

.footer {
    background-color: #1C5838;
    font-size: 0.8em;
    color: #f1f1f1;
}

@media only screen and (max-width: 600px) {
    .banner {
        background-size: 80%;
        background-position-x: right;
        background-position-y: bottom;
    }

    .feature-card {
        min-height: 420px;
    }

    .main-text {
        font-size: 2em;
        text-align: left;
        padding: 0 30px;
        margin-top: -2em;
    }

    .banner a {
        margin: 0 30px;
    }

    .about-wrapper {
        text-align: center;
        padding: 30px;
    }

    .about-wrapper img {
        margin-left: -50px;
    }

    .niche-wrapper img {
        height: auto;
        width: 100%;
    }

    .niche-body-right {
        left: 0;
        top: -25px;
        border-left: solid 0px #0DC31F;
        border-top: solid 10px #0DC31F;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .niche-body-left {
        border-right: solid 0px #0DC31F;
        border-bottom: solid 10px #0DC31F;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        right: 0;
        left: 0;
        top: 25px;
    }

    .niche-wrapper .icon-wrapper-right {
        right: 0;
    }

    .niche-wrapper .icon-wrapper-left {
        left: 0;
        display: none;
    }

    .niche-body {
        width: 100%;
    }
}

.partners {
    width: 100%;
    height: 60vh;
}